import React from "react";

export const links = [
  {
    id: 1,
    url: "/",
    text: "Kezdőlap",
  },
  {
    id: 2,
    url: "#about",
    text: "Tevékenységek",
  },
  {
    id: 3,
    url: "#projects",
    text: "Projektek",
  },
  {
    id: 4,
    url: "#cv",
    text: "Önéletrajz",
  },
  {
    id: 5,
    url: "#contact",
    text: "Kapcsolat",
  },
];

export const linksENG = [
  {
    id: 1,
    url: "/",
    text: "Home",
  },
  {
    id: 2,
    url: "#about",
    text: "Activities",
  },
  {
    id: 3,
    url: "#projects",
    text: "Projects",
  },
  {
    id: 4,
    url: "#cv",
    text: "CV",
  },
  {
    id: 5,
    url: "#contact",
    text: "Contact",
  },
];
