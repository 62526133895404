import React, { useEffect, useRef, useState } from 'react';
import { links } from '../../data/data';
import { FaBars } from 'react-icons/fa';
import {GiSpotedFlower} from 'react-icons/gi';

import { Text, LanguageContext } from '../../context/Language';

import './navbar.css';
import { useContext } from 'react';
import LanguageSelector from '../languageSelector/LanguageSelector';

const Navbar = () => {
    const [showLinks, setShowLinks] = useState(false);
    const linksContainerRef = useRef(null);
    const linksRef = useRef(null);

    const {dictionary} = useContext(LanguageContext);

    useEffect(() => {
        const linksHeight = linksRef.current.getBoundingClientRect().height;
        console.log(linksHeight);
        if(showLinks){
            linksContainerRef.current.style.height = `${linksHeight}px`;
        }
        else {
            linksContainerRef.current.style.height ='0px';
        }

    }, [showLinks]);


    return (
    <nav>
        <div className="nav-center">
            <div className="nav-header">
                <p className='img-logo'><a href="#"><GiSpotedFlower/></a></p>
                <p className='text-logo'>Judy<span>Projects</span></p>
                <button className="nav-toggle" onClick={() => setShowLinks(!showLinks)}><FaBars/></button>
            </div>
            
                <div className="links-container" ref={linksContainerRef}>
                    <ul className="links" ref={linksRef}>                
                        {
                            dictionary.links.map((link) => {
                                const {id, url, text} = link;
                                return(
                                    <li key={id}><a href={url} onClick={() => setShowLinks(false)}>{link.text}</a></li>
                                )
                            })
                        }
                        <LanguageSelector/>
                    </ul>
                    
                </div>
            
                
            
        </div>
        
    </nav>
    )
}

export default Navbar