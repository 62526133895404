import React from 'react'
import Title from '../title/Title'
import { useContext } from 'react'
import { LanguageContext } from '../../context/Language'
import CvCard from '../cvCard/CvCard'
import './CV.css'

const CV = () => {
    const { dictionary } = useContext(LanguageContext);
  return (
    <div className='cv' id='cv'>
        <Title title={dictionary.cvTitle}/>
        <div className="cv-container ">
            <CvCard title={dictionary.cvCardTitle1} file="CV_NagyJudit_en_2023_v2.pdf"/>
            <CvCard title={dictionary.cvCardTitle2} file="CV_NagyJudit_hu_2023_v2.pdf"/>
            {/* <p>Az önéletrajz <a href="./files/CV_nagyJudit_hu.pdf" target='_blank'>ide</a> kattintva letölthető.</p> */}
        </div>
    </div>
    
  )
}

export default CV