import React from 'react'
import Title from '../title/Title'
import { useContext } from 'react'
import { LanguageContext } from '../../context/Language'
import './About.css'

const About = () => {
    const { dictionary } = useContext(LanguageContext);
  return (
    <div className='about' id='about'>
        <Title title={dictionary.aboutTitle}/>
        <div className="about-container">
            <div className="about-container-left">
                <ul>
                    {
                        dictionary.aboutList.map(item => (
                            <li><span>{item}</span></li>
                        ))
                    }            
                </ul>
                <div className="about-skills">
                    {
                        dictionary.aboutSkills.map(item => (
                            <span>{item}</span>
                        ))
                    }
                </div>
            </div>
            <div className="about-container-right">
                <div className="about-technology">
                    <h4>{dictionary.technologyFrontend}</h4>
                    <div className="about-right-item">
                        <div>
                            <p>JavaScript</p>
                            <p>React JS</p>
                        </div>
                        <div className='about-icons'>
                            <img src="https://cdn-icons-png.flaticon.com/512/5968/5968292.png" alt="JavaScript logo" />
                            <img src="https://cdn-icons-png.flaticon.com/512/1126/1126012.png" alt="React icon" />
                        </div>
                    </div>
                    
                </div>
                <div className="about-technology">
                    <h4>{dictionary.technologyBackend}</h4>
                    <div className="about-right-item">
                        <div className="about-database">
                            <p>Node.JS</p>
                            <p>Python</p>
                        </div>
                        <div className='about-icons'>
                            <img src='https://cdn-icons-png.flaticon.com/512/5968/5968322.png' alt='NODE JS icon'></img>
                            <img src="https://cdn-icons-png.flaticon.com/512/5968/5968350.png" alt="Python" />
                        </div>
                    </div>
                    
                </div>
                <div className="about-technology">
                    <h4>{dictionary.technoloyDatabase}</h4>
                    <div className='about-right-item'>
                        <div className='about-technology'>
                            <p>MySQL</p>
                            <p>Miscrosoft SQL</p>
                            <p>GraphQL</p>
                        </div>
                        <div className='about-icons'>
                            <img src="https://cdn-icons-png.flaticon.com/512/919/919836.png" alt="MySQL icon" />
                            <img src="https://cdn-icons-png.flaticon.com/512/5968/5968364.png" alt="Microsoft SQl" />
                            
                        </div>
                    </div>
                    
                </div>
                <div className="about-technology">
                    <h4>{dictionary.technologyOther}</h4>
                    <div className='about-right-item'> 
                        <div className='about-technology'>
                            <p>Microsoft Office</p>
                            <p>Google G Suite</p>
                            <p>HTML</p>
                            <p>CSS / SASS</p>
                            <p>Amazon Web Service</p>
                        </div>                       
                        <div className='about-icons'>
                            <img src="https://cdn-icons-png.flaticon.com/512/732/732226.png" alt="Microsoft Word" />
                            <img src="https://cdn-icons-png.flaticon.com/512/732/732220.png" alt="Microsoft Excel" />
                            <img src="https://cdn-icons-png.flaticon.com/512/888/888874.png" alt="Microsoft Power Point" />
                            <img src="https://cdn-icons-png.flaticon.com/512/732/732218.png" alt="Microsoft Access" />
                            <img src="https://cdn-icons-png.flaticon.com/512/5968/5968523.png" alt="Google Drive logo" />
                            <img src="https://cdn-icons-png.flaticon.com/512/5968/5968552.png" alt="Google Meet logo" />
                            <img src="https://cdn-icons-png.flaticon.com/512/1051/1051277.png" alt="HTML icon" />
                            <img src="https://cdn-icons-png.flaticon.com/512/732/732190.png" alt="CSS icon" />
                            <img src="https://cdn-icons-png.flaticon.com/512/919/919831.png" alt="Sass icon" />
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
  )
}

export default About