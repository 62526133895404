import React from 'react'
import { Link } from 'react-router-dom'
import './CvCard.css'
import { useContext } from 'react'
import { LanguageContext } from '../../context/Language'

const CvCard = ({title, file}) => {
  const {dictionary} = useContext(LanguageContext);
  return (
    
    <div className='cvCard'>
        <div className='cvCardTitle'>
            <div>{title}</div> 
        </div>
        <div className="cvCardLayer">
            <div><a href={`./files/${file}`} target="_blank">{dictionary.download}</a></div>
        </div>
    </div>
    
  )
}

export default CvCard