import React, { useContext } from 'react'
import './LanguageSelector.css'

import { languageOptions } from '../../languages'
import { LanguageContext } from '../../context/Language'

const LanguageSelector = () => {
    const { userLanguage, userLanguageChange} = useContext(LanguageContext);

    const handleLanguageChange = e => userLanguageChange(e.target.value);
  return (
    <div className="languageSelector">
      <select onChange={handleLanguageChange} value={userLanguage} className="selector">
        {Object.entries(languageOptions).map(([id, name]) => (
            <option key={id} value={id}>{name}</option>
        ))}
      </select>
    </div>
    
  )
}

export default LanguageSelector