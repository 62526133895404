import React, { useState, useEffect } from 'react'
import { useContext } from 'react';
import img from '../../assets/home/computer.jpg';
import { LanguageContext } from '../../context/Language';
import './Hero.css'

const Hero = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const {dictionary} = useContext(LanguageContext);
    useEffect(() => {
        const timer = setTimeout(() => {
            currentIndex === 0 ? setCurrentIndex(1) : setCurrentIndex(0);
            
        }, 4000);
        return () => clearTimeout(timer);
    }, [currentIndex])
  return (
    <div className='hero'>
        <div className="hero-content" style={{transform: `translateX(-${currentIndex*100}vw)`}}>
            <div className="itemHero">
                <img src="https://images.pexels.com/photos/196655/pexels-photo-196655.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
                <div className="itemText">
                    <h3>{dictionary.heroText1}</h3>            
                    <button className="btn-hero"><a href="#contact">{dictionary.heroBtn1}</a> </button>
                </div>
                <div className="layerHero"></div>
            </div>
            <div className="itemHero">
                <img src="https://images.pexels.com/photos/1595391/pexels-photo-1595391.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="" />
                <div className="itemText">
                    <h3>{dictionary.heroText2}</h3>            
                    <button className="btn-hero"><a href="#education">{dictionary.heroBtn2}</a> </button>
                </div>
                <div className="layerHero"></div>
            </div>
            
        </div>
    </div>
  )
}

export default Hero