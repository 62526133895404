import React from 'react'
import './Title.css'


const Title = ({title}) => {
  
  return (
    <div className='title-container'>
        <h2 className='heading-text'>{title}</h2>
    </div>
  )
}

export default Title