import "./App.css";

import { LanguageProvider } from "./context/Language";

import LanguageSelector from "./components/languageSelector/LanguageSelector";
import Explore from "./components/explore/Explore";
import Navbar from "./components/navbar/Navbar";
import Hero from "./components/hero/Hero";
import Projects from "./components/projects/Projects";
import CV from "./components/cv/CV";
import Contact from "./components/contact/Contact";
import About from "./components/about/About";

function App() {
  return (
    <LanguageProvider>
      <div className="App">
        {/* <Explore /> */}
        <Navbar />
        <Hero />
        <About />
        <Projects />
        <CV />
        <Contact />
      </div>
    </LanguageProvider>
  );
}

export default App;
