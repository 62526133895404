import React from 'react'
import './SingleProject.css'

const SingleProjectCard = ({img, title, name, technology, description, url}) => {
  return (
    <div className='singleProjectCard'>
      <a href={url} target="_blank">
        <div className="img-container">
          <img src={img} alt="" />
          <div className='singleProjectLayer'></div>
        </div>
      </a>
      
      <div className="singleProjectCard-container">
        <h3>{title}</h3>
        <h4>{name}</h4>
        <p>{description}</p>
        <div className="singleProjectCard-technology">
          {
            technology.map((item, idx) => (
              <p key={idx}>{item}</p>
            ))
          }
        </div>
        
      </div>
    </div>
  )
}

export default SingleProjectCard