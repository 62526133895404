import React from 'react'
import './Contact.css'
import { FaMobileAlt } from 'react-icons/fa'
import {HiOutlineMail} from 'react-icons/hi'
import {BsGithub, BsLinkedin} from 'react-icons/bs'
import { useContext } from 'react'
import { LanguageContext } from '../../context/Language'

const Contact = () => {
  const { dictionary } = useContext(LanguageContext);
  return (
    <section className="contact" id="contact">
        <div className="contact-container">
            <h2 className='heading-text contactTitle' style={{color: "white", textAlign: "left", margin: "2rem 0"}}>{dictionary.contactTitle}</h2>
           
            <div className="contact-container-left">
                <p className='contact-info'><FaMobileAlt className='icon'/><a href='tel:+36304774893'>: +36 30 4774 893</a></p>
                <p className='contact-info'> <HiOutlineMail className='icon'/><a href='mailto:nagyjudit512@gmail.com'>: nagyjudit512@gmail.com</a></p>
                <div className="contact-icons-social">
                    <a href="https://github.com/jud512" target="_blank"> <BsGithub /></a>
                    <a href="https://www.linkedin.com/in/judit-nagy-7b9874242" target="_blank"><BsLinkedin /></a>
                </div>
                
            </div>
            

            <p className='author'>© <a href='https://judyprojects.hu'>JudyProjects, </a>2023 <br/> All rights reserved.</p>
            
        </div>
    </section>
  )
}

export default Contact