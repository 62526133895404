import React from 'react'
import Title from '../title/Title'
import { useContext } from 'react'
import { LanguageContext } from '../../context/Language'
import SingleProjectCard from '../singleProjectCard/SingleProject'
import './Projects.css'

const Projects = () => {
  const {dictionary} = useContext(LanguageContext);
  return (
    <div className='projects' id='projects'>
        <Title title={dictionary.projectTitle}/>
        <div className="projects-container">
          {
            dictionary.projects.map((project) => {
              return <SingleProjectCard key={project.id} img={project.image} title={project.title} name={project.name} technology={project.technology} description={project.description} url={project.url}/>
            })
          }
          
        </div>
    </div>
  )
}

export default Projects